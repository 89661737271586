import { defineStore } from 'pinia';

import {
  apiGetTrailerDetail,
  apiTrailerAuth,
  apiCheckTrailerNumber,
  updateTrailerInfoApi,
  apiAddTrailerByCarNumber
} from '@api/trailer';
import { useUserStore } from '@/store/user';
import { Toast, Dialog } from 'vant';

import { formatToDateTime } from '@wlhy-web-lib/shared';
import { plateEnumMap, carsTypeEnumMap } from '@/views/mine/constants';
import { cloneDeep } from 'lodash-es';

import { objectCompare } from '@/utils/authMode';
import dayjs from 'dayjs';
import { PageEnum } from '@/enums/pageEnum';
import router from '@/router';
const parseTimeStr = (time, isEndofMonth = false) => {
  if (!time || !dayjs(time).isValid()) return null;
  return isEndofMonth
    ? dayjs(time)
        .endOf('month')
        .valueOf() - 1000
    : dayjs(time).valueOf();
};
const parseTime = (time, format = 'yyyy-MM-dd') => {
  if (!time) return null;
  return formatToDateTime(time, format);
};
export const emptyForm = () => ({
  step: 0,
  businessName: '', // 业户名称
  carLicensePlate: '', // 车牌号
  driverId: '', // 司机id
  drivingLicenseMainUrl: '', // 行驶证主页url
  drivingLicenseSideUrl: '', // 行驶证其他页url
  energyTypeCode: 'Y', // 能源类型
  energyType: '无',
  fileNumber: '', // 档案编号
  identifyCode: '', // 车辆识别代号
  issueDate: '', // 行驶证发证日期
  issueOrganization: '', // 行驶证发证机关
  loadWeight: '', // 核定载质量
  curbWeight: null,
  inspectionEndDate: null,
  validEndDate: null,
  outlineDimensionHeight: '', // 外廓尺寸高
  outlineDimensionLength: '', // 外廓尺寸长
  outlineDimensionWidth: '', // 外廓尺寸宽
  owner: '', // 车辆所有人
  plateColor: '', // 车牌颜色
  registerDate: '', // 行驶证注册日期
  roadTransportNumber: '', // 道路运输证号
  roadTransportPermitNumber: '', // 道路运输许可证号
  totalWeight: '', // 总质量
  //   trailerPlateNumber: '', // 挂车牌照号
  transportLicenseMainUrl: '', //道路运输证
  transportLicenseSideUrl: '', // 道路运输证其他页
  trailerId: null, // 车辆ID
  truckTypeCode: '', // 车辆类型代码
  truckTypeName: '', // 车辆类型名称
  useNature: '', // 使用性质
  reasonKeys: []
});

const refuseReasonKeys = {
  truckTypeCode: 'truckTypeName',
  drivingLicenseMainFilename: 'drivingLicenseMainUrl'
};

export const useTrailerVerifiedStore = defineStore({
  id: 'truck-verified',
  state: () => ({
    verifiedInfo: Object.assign({}, emptyForm()),

    isAmendTruckAuthInfo: false, // 表示 认证通过重新认证的状态, 废弃
    // 模式认证配置
    authModeFields: {},
    originVerifiedInfo: {}, // 用来比对数据
    imagePass: false, // 行驶证是否通过
    transportImagePass: false, // 道路运输证是否通过
    auditStatusInfo: 0,
    roadDisabled: false
  }),
  actions: {
    updateInfo(val) {
      this.verifiedInfo = Object.assign(this.verifiedInfo, val);
    },
    updateImagePass(val) {
      this.imagePass = val;
    },
    updateTransportImagePass(val) {
      this.transportImagePass = val;
    },
    updateAuditStatusInfo(val) {
      this.auditStatusInfo = val;
    },
    updateAuthModeFields(fields) {
      this.authModeFields = fields;
    },
    clearInfo() {
      this.verifiedInfo = { ...emptyForm() };
      this.verifiedInfo.carLicensePlate = '';
      this.verifiedInfo.drivingLicenseMainUrl = '';
      this.verifiedInfo.drivingLicenseSideUrl = '';
      this.verifiedInfo.step = 0;
      this.isAmendTruckAuthInfo = false;
      this.imagePass = false;
      this.transportImagePass = false;
      this.auditStatusInfo = 0;
      this.roadDisabled = false;
    },
    async updateVerifiedInfo() {
      const userStore = useUserStore();

      Toast.loading({
        duration: 100, // 持续展示 toast
        message: '车辆数据上传中...'
      });
      try {
        const { carLicensePlate, truckTypeCode, truckTypeName, ...verifiedInfo } = this.verifiedInfo;
        const params = {
          // 进行数据处理
          ...verifiedInfo,
          driverId: userStore?.driverAuthVO?.id,
          issueDate: parseTimeStr(verifiedInfo.issueDate),
          registerDate: parseTimeStr(verifiedInfo.registerDate),
          inspectionEndDate: parseTimeStr(verifiedInfo.inspectionEndDate, true),
          validEndDate: parseTimeStr(verifiedInfo.validEndDate),
          outlineDimensionLength: +verifiedInfo.outlineDimensionLength,
          outlineDimensionWidth: +verifiedInfo.outlineDimensionWidth,
          outlineDimensionHeight: +verifiedInfo.outlineDimensionHeight,
          loadWeight: +verifiedInfo.loadWeight * 1000,
          totalWeight: +verifiedInfo.totalWeight * 1000,
          curbWeight: verifiedInfo.curbWeight ? +verifiedInfo.curbWeight * 1000 : null,
          energyType: verifiedInfo.energyTypeCode,
          trailerTypeCode: truckTypeCode,
          trailerTypeName: truckTypeName,
          trailerPlateNumber: carLicensePlate,
          plateColor: +verifiedInfo.plateColorCode
        };

        let res;

        res = await updateTrailerInfoApi(params);

        const { data } = res;

        if (data) {
          this.updateInfo({
            step: 3
          });
        }
      } catch (err) {
        console.log(err);
      } finally {
        setTimeout(() => {
          Toast.clear();
        }, 1000);
      }
    },
    setRoadDisabled(val) {
      this.roadDisabled = val;
    },
    //新增接口
    async submitVerifiedInfo() {
      // 添加车辆
      const userStore = useUserStore();
      Toast.loading({
        duration: 100, // 持续展示 toast
        message: '车辆数据上传中...'
      });
      try {
        const { carLicensePlate, truckTypeCode, truckTypeName, ...verifiedInfo } = this.verifiedInfo;
        const params = {
          // 进行数据处理
          ...verifiedInfo,
          driverId: userStore?.driverAuthVO?.id,
          issueDate: parseTimeStr(verifiedInfo.issueDate),
          registerDate: parseTimeStr(verifiedInfo.registerDate),
          inspectionEndDate: parseTimeStr(verifiedInfo.inspectionEndDate, true),
          validEndDate: parseTimeStr(verifiedInfo.validEndDate),
          outlineDimensionLength: +verifiedInfo.outlineDimensionLength,
          outlineDimensionWidth: +verifiedInfo.outlineDimensionWidth,
          outlineDimensionHeight: +verifiedInfo.outlineDimensionHeight,
          loadWeight: +verifiedInfo.loadWeight * 1000,
          totalWeight: +verifiedInfo.totalWeight * 1000,
          curbWeight: verifiedInfo.curbWeight ? +verifiedInfo.curbWeight * 1000 : null,
          energyType: verifiedInfo.energyTypeCode,
          trailerTypeCode: truckTypeCode,
          trailerTypeName: truckTypeName,
          trailerPlateNumber: carLicensePlate,
          plateColor: +verifiedInfo.plateColorCode
        };

        const res = await apiTrailerAuth(params);

        const { data } = res;

        if (data) {
          this.updateInfo({
            step: 3
          });
        }
      } catch (err) {
        console.log(err);
      } finally {
        setTimeout(() => {
          Toast.clear();
        }, 1000);
      }
    },

    async queryVerifiedInfo(trailerId, resolve) {
      // 获取车辆详情
      Toast.loading({
        duration: 0, // 持续展示 toast
        message: '车辆数据加载中...'
      });
      try {
        const res = await apiGetTrailerDetail({
          trailerId
        });
        const { data } = res;
        if (data) {
          const { step, driverId, auditStatus, energyType, trailerId, registerDate, issueDate, ...fieldsMap } = data;

          this.verifiedInfo = {
            ...data,
            step: 3,
            trailerId,
            issueDate: parseTime(data.issueDate, 'yyyy-MM-dd'),
            registerDate: parseTime(data.registerDate, 'yyyy-MM-dd'),
            inspectionEndDate: parseTime(data.inspectionEndDate, 'yyyy-MM'),
            validEndDate: parseTime(data.validEndDate, 'yyyy-MM-dd'),
            loadWeight: data.loadWeight / 1000,
            totalWeight: data.totalWeight / 1000,
            curbWeight: data.curbWeight ? data.curbWeight / 1000 : null,
            energyTypeCode: data.energyType,
            energyType: carsTypeEnumMap[data.energyType], // 枚举
            plateColorCode: data.plateColor,
            plateColor: plateEnumMap[data.plateColor], // 枚举
            truckTypeName: data.trailerTypeName,
            carLicensePlate: data.trailerPlateNumber,
            reasonKeys: data?.refuseReason?.map(item => refuseReasonKeys[item.item] || item.item) || []
          };
        }
      } catch (err) {
        console.log(err);
      } finally {
        typeof resolve === 'function' && resolve();
        setTimeout(() => {
          Toast.clear();
        }, 500);
      }
    },
    async checkTruckExist(carLicensePlate) {
      const userStore = useUserStore();
      Toast.loading({
        duration: 0, // 持续展示 toast
        message: '车牌号校验中...'
      });
      try {
        const res = await apiCheckTrailerNumber({
          carLicensePlate,
          driverId: userStore?.driverAuthVO?.id
        });
        const { data } = res;
        return data ? 1 : 0;
      } catch (e) {
        return -1;
      } finally {
        setTimeout(() => {
          Toast.clear();
        }, 500);
      }
    },
    async addTruckByExist(carLicensePlate) {
      const userStore = useUserStore();
      Toast.loading({
        duration: 0, // 持续展示 toast
        message: '添加中...'
      });
      try {
        await apiAddTrailerByCarNumber({
          carLicensePlate,
          driverId: userStore?.driverAuthVO?.id
        });

        this.clearInfo();
        return true;
      } catch (e) {
        return false;
      } finally {
        Toast.clear();
      }
    },
    async queryCheckTruck(resolve, reject) {
      const userStore = useUserStore();

      Toast.loading({
        duration: 0, // 持续展示 toast
        message: '车牌号校验中...'
      });
      try {
        const res = await apiCheckTrailerNumber({
          carLicensePlate: this.verifiedInfo.carLicensePlate,
          driverId: userStore?.driverAuthVO?.id
        });

        const { data } = res;
        if (!data) {
          typeof resolve === 'function' && resolve();
        } else {
          Dialog.confirm({
            message: '车辆添加成功!',
            confirmButtonText: '点击查看',
            closeOnClickOverlay: true
          })
            .then(async () => {
              await this.clearInfo();
              typeof reject === 'function' && reject();
            })
            .catch(() => {});
        }
      } catch (err) {
        await this.clearInfo();
        setTimeout(() => {
          typeof reject === 'function' && reject();
        }, 500);
      } finally {
        setTimeout(() => {
          Toast.clear();
        }, 500);
      }
    }
  }
});

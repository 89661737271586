<template>
  <Page class="mine-car pb-72 pt-16">
    <Navbar title="挂车认证" @clickLeft="handleClickBack" :customClickLeft="true"> </Navbar>
    <div class="bg-white h-full overflow-scroll">
      <!-- 进度条 -->
      <TosbStep class="step pa-16 pb-0" :steps="steps" :current="step" />

      <!-- 步骤一 -->
      <div class="pt-34">
        <component v-if="step > -1" :is="currentStepComponent"></component>
      </div>
    </div>
  </Page>
</template>
<script>
import { defineComponent, computed, onBeforeMount, nextTick } from '@vue/composition-api';
import { Button, Dialog } from 'vant';
import TosbStep from '@/components/Step/index.vue';

import { steps } from '../../../constants/index';
import { useRouter } from '@/utils/compsitionHack';
import { useRouterQuery } from '@hook/useRouterQuery';
import { useUserStore } from '@/store/user';
import { PageEnum } from '@/enums/pageEnum';

import { useTrailerVerifiedStore, emptyForm } from '@/store/trailer';

export default defineComponent({
  name: 'AddCar',
  components: {
    Button,
    TosbStep,
    Step1: () => import('../components/step1'),
    Step2: () => import('../components/step2'),
    Step3: () => import('../components/step3'),
    Step4: () => import('../components/step4')
  },
  setup() {
    const router = useRouter();
    const userStore = useUserStore();
    const routerQuery = useRouterQuery();

    const userVerifiedStore = useTrailerVerifiedStore();
    const step = computed(() => userVerifiedStore.verifiedInfo.step || 0);
    // 判断当前显示步骤几的组件
    const currentStepComponent = computed(() => {
      console.log(`Step${step.value + 1}`);
      return `Step${step.value + 1}`;
    });

    onBeforeMount(async () => {
      if (routerQuery.truckId) {
        // 编辑状态
        await userVerifiedStore.queryVerifiedInfo(routerQuery.truckId);
        userVerifiedStore.updateInfo({
          step: 0
        });
      } else {
        userVerifiedStore.updateInfo(emptyForm());
        userVerifiedStore.updateInfo({
          step: 0
        });
      }
    });

    const sureBack = async () => {
      // 如果是在客户端环境，调用客户端返回方法
      userVerifiedStore.clearInfo();
      // 不同的身份跳转不同地方
      nextTick(() => {
        router.push(PageEnum.BASE_CAR_LIST);
      });
    };

    const handleClickBack = () => {
      if (step.value === 0 || step.value === 3) {
        if (step.value === 0) {
          Dialog.confirm({
            message: '信息暂未提交认证，退出后信息不会保存，是否继续操作!'
          })
            .then(() => {
              sureBack();
            })
            .catch(() => {});
        } else {
          sureBack();
        }
      } else {
        userVerifiedStore.updateInfo({
          step: step.value - 1
        });
      }
    };

    return {
      currentStepComponent,
      steps,
      step,
      handleClickBack
    };
  }
});
</script>
<style lang="less">
.mine-car {
  min-height: 100vh;
  &.keyboard {
    padding-bottom: 0.3rem !important;
  }
}
.van-field__error-message {
  text-align: right;
}
.carnumber-box {
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  font-size: 0.2rem;
  line-height: 0.5rem;
  border-radius: 0.05rem;
  text-align: center;
  border: 1px solid #eee;
  + .carnumber-box {
    margin-left: 0.02rem;
  }
}
.titleError {
  color: #ee0a24;
  .van-field__label {
    color: #ee0a24;
  }
}
</style>
